import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Avatar,
  Button,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@emotion/react';
import { MoreVert, ShareOutlined, Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useRouter } from '../../components/hooks';
import FeedContent from './feeds/feedContent';

const Feeds = () => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const router = useRouter();

  const [feedData, setFeedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [playingVideos, setPlayingVideos] = useState({});
  const videoRefs = useRef([]);
  const observerRefs = useRef([]);
  const theme = useTheme();

  const fetchFeed = async (page) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://soapboxapi.megahoot.net/upload/public/feeds/all?page=${page}&limit=10`
      );
      setFeedData((prevData) => [...prevData, ...res.data.results]);
      setHasMore(res.data.results.length > 0);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (hootId) => {
    try {
      await axios.put('https://soapboxapi.megahoot.net/upload/increment-like', {
        hootId,
      });
      setFeedData((prevData) =>
        prevData.map((post) =>
          post.hootId === hootId ? { ...post, likes: post.likes + 1 } : post
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleRepost = async (postId) => {
    if (window.confirm('Are you sure you want to repost this post?')) {
      try {
        await axios.post('https://soapboxapi.megahoot.net/upload/repost', {
          postId,
          username: userInfo.username,
          email: userInfo.email,
        });
        alert('Reposted from your timeline');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const togglePlayPause = (index) => {
    const video = videoRefs.current[index];
    if (playingVideos[index]) {
      video.pause();
    } else {
      video.play();
    }
    setPlayingVideos((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Scroll to the next media in the list
  const scrollToNext = (currentIndex) => {
    if (currentIndex < feedData.length - 1) {
      const nextItem = document.getElementById(`feed-item-${currentIndex + 1}`);
      if (nextItem) {
        nextItem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const createObserver = (index) => {
    try {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            const item = feedData[index];
            if (item.mimeType.includes('video')) {
              try {
                videoRefs.current[index].play();
                videoRefs.current[index].onended = () => {
                  scrollToNext(index);
                };
              } catch (error) {
                console.log(error);
              }
            } else {
              setTimeout(() => {
                scrollToNext(index);
              }, 5000); // Scroll to next image after 10 seconds
            }
          } else {
            if (videoRefs.current[index] && !videoRefs.current[index].paused) {
              videoRefs.current[index].pause();
            }
          }
        },
        { threshold: 0.7 } // Only trigger when 70% of the item is visible
      );

      observer.observe(observerRefs.current[index]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFeed(1);
  }, []);

  useEffect(() => {
    feedData.forEach((_, index) => {
      createObserver(index);
    });
  }, [feedData]);

  return (
    <Grid item xs={12} sm={12}>
      <InfiniteScroll
        height={'100vh'}
        dataLength={feedData.length}
        next={() => fetchFeed(Math.ceil(feedData.length / 10) + 1)}
        hasMore={hasMore}
        loader={
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        }
        style={{ flex: 1, overflowY: 'auto' }}
      >
        {feedData.map((item, index) => (
          <Card
            // onClick={() => router.push(`/feed/${item.id}`)}
            key={index}
            id={`feed-item-${index}`}
            ref={(el) => (observerRefs.current[index] = el)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 'auto',
              maxWidth: '400px',
              margin: '0 auto',
              my: 5,
              borderRadius: '20px',
              position: 'relative',
              backgroundColor: '#fff',
              padding: '1px',
            }}
          >
            {item.mimeType.includes('video') ? (
              <>
                <CardMedia
                  component="video"
                  loading="lazy"
                  src={`https://soapboxapi.megahoot.net/images/${item.image}`}
                  poster={`https://soapboxapi.megahoot.net/images/${item.audioPoster}`}
                  ref={(el) => (videoRefs.current[index] = el)}
                  controls={true}
                  sx={{ width: 'auto', height: '70vh', objectFit: 'cover' }}
                  muted={true}
                  onClick={() => togglePlayPause(index)}
                />
                {/* <IconButton
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: theme.palette.primary.main,
                    zIndex: 2,
                  }}
                  onClick={() => togglePlayPause(index)}
                >
                  {playingVideos[index] ? (
                    <PauseIcon sx={{ fontSize: 40, color: '#fff' }} />
                  ) : (
                    <PlayArrowIcon sx={{ fontSize: 40, color: '#fff' }} />
                  )}
                </IconButton> */}
              </>
            ) : (
              <CardMedia
                component="img"
                loading="lazy"
                image={`https://soapboxapi.megahoot.net/images/${item.image}`}
                alt="feed"
                sx={{ width: '100%', height: '70vh', objectFit: 'cover' }}
              />
            )}
            <FeedContent hoot={item} setFeedData={setFeedData} />
          </Card>
        ))}
      </InfiniteScroll>
    </Grid>
  );
};

export default Feeds;
